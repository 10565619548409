import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './Navbar';
import Home from './Home';
import Pricing from './Pricing';
import HomeMenu from './gameSelect';
import Contact from './Contact';
import Cane from './Cane';
import './index.css'; // Import the CSS file
import ScrollToTop from './ScrollToTop'; // Adjust the path as necessary
import Relief from './TampaStrong';
import Game from './game';

import Oh1 from './oh1';
import Three01 from './three01';
import Seven01 from './seven01';

// Layout with Navbar
const LayoutWithNavbar = ({ children }) => (
  <>
    <Navbar />
    {children}
  </>
);

// Layout without Navbar
const LayoutWithoutNavbar = ({ children }) => (
  <>
    {children}
  </>
);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      <Routes>
        {/* Routes with Navbar */}
        <Route path="/" element={<LayoutWithNavbar><Home /></LayoutWithNavbar>} />
        <Route path="/pricing" element={<LayoutWithNavbar><Pricing /></LayoutWithNavbar>} />
        <Route path="/contact" element={<LayoutWithNavbar><Contact /></LayoutWithNavbar>} />
        <Route path="/hurricaneRelief" element={<LayoutWithNavbar><Relief /></LayoutWithNavbar>} />

        {/* Routes without Navbar */}
        <Route path="/validUser575" element={<LayoutWithoutNavbar><HomeMenu /></LayoutWithoutNavbar>} />
        <Route path="/cricket" element={<LayoutWithoutNavbar><Game /></LayoutWithoutNavbar>} />
        <Route path="/501" element={<LayoutWithoutNavbar><Oh1 /></LayoutWithoutNavbar>} />
        <Route path="/301" element={<LayoutWithoutNavbar><Three01 /></LayoutWithoutNavbar>} />
        <Route path="/701" element={<LayoutWithoutNavbar><Seven01 /></LayoutWithoutNavbar>} />
      </Routes>
    </Router>
  );
};

export default App;
