import React, { useState } from 'react';
import { Button, Container, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './01.css'; // Ensure CSS file is imported
import skp from './fl.png'; // Path to your skp image
import carbon from './carbon.png'; // Path to your carbon background image

const HomeMenu = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleButton1Click = () => {
    navigate('/cricket');
  };

  const handleButton2Click = () => {
    setOpen(true); // Open the dialog
  };

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  const handleDialogButton1 = () => {
    navigate('/301'); // Navigate to /three01
    handleClose();
  };

  const handleDialogButton2 = () => {
    navigate('/701'); // Navigate to /seven01
    handleClose();
  };

  const handleDialogButton3 = () => {
    navigate('/501'); // Navigate to /oh1 or update to another route if needed
    handleClose();
  };

  return (
    <Container
      style={{
        backgroundImage: `url(${carbon})`, // Use the carbon background image
        backgroundSize: 'cover', // Ensure the background covers the entire area
        backgroundPosition: 'center', // Center the background image
        backgroundRepeat: 'no-repeat', // Do not repeat the background image
        minHeight: '100vh', // Ensure the background takes up the full height
        marginTop:'0px'
      }}
    >
      <Grid
        container
        direction="column" // Stack image on top and buttons below
        alignItems="center"
        justifyContent="center"
        spacing={4}
        style={{ minHeight: '100vh' }}
      >
        {/* Image Section */}
        <Grid item>
          <img src={skp} alt="SKP Logo" style={{ width: '60%', height: 'auto', marginBottom: '20px' }} />
        </Grid>

        {/* Buttons Section (Side by Side) */}
        <Grid item>
          <Grid container spacing={2} direction="row" alignItems="center" justifyContent="center">
            <Grid item>
              <Button 
                variant="contained" 
                onClick={handleButton1Click}
                style={{ 
                  backgroundColor: 'green', // Custom lime green hex color
                  color: 'white', // Text color
                  width: '200px', // Larger width
                  height: '70px', // Larger height
                  fontSize: '1.5rem', // Larger font size
                  padding: '16px' // Adjust padding if needed
                }}
              >
                Cricket
              </Button>
            </Grid>
            <Grid item>
              <Button 
                variant="contained" 
                onClick={handleButton2Click}
                style={{ 
                  backgroundColor: 'green', // Custom lime green hex color
                  color: 'white', // Text color
                  width: '200px', // Larger width
                  height: '70px', // Larger height
                  fontSize: '1.5rem', // Larger font size
                  padding: '16px' // Adjust padding if needed
                }}
              >
                301/501/701
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {/* Dialog Component */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Choose Game Type</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Please select one of the following target scores!</Typography>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleDialogButton1} 
            variant="contained" 
            style={{ 
              backgroundColor: 'green', // Custom lime green hex color
              color: 'white', // Text color
              width: '100px', // Adjust width
              height: '50px', // Adjust height
              fontSize: '1rem', // Font size
            }}
          >
            301
          </Button>
          <Button 
            onClick={handleDialogButton3} 
            variant="contained" 
            style={{ 
              backgroundColor: 'green', // Custom lime green hex color
              color: 'white', // Text color
              width: '100px', // Adjust width
              height: '50px', // Adjust height
              fontSize: '1rem', // Font size
            }}
          >
            501
          </Button>
          <Button 
            onClick={handleDialogButton2} 
            variant="contained" 
            style={{ 
              backgroundColor: 'green', // Custom lime green hex color
              color: 'white', // Text color
              width: '100px', // Adjust width
              height: '50px', // Adjust height
              fontSize: '1rem', // Font size
            }}
          >
            701
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default HomeMenu;
