import React, { useState, useRef, useEffect, useCallback } from "react";
import "./game.css";
import "./App.css";
//import blankAd from './icons/contact.png'

import Webcam from "react-webcam";
import WebcamImage from "./WebcamImage";
import { useNavigate } from 'react-router-dom';
import './DartboardAndScoreboard.css'; // Import the CSS file for styling


import guy1 from './icons/avs/a1.png'
import guy2 from './icons/avs/a2.png'
import guy3 from './icons/avs/a3.png'
import guy4 from './icons/avs/a4.png'
import guy5 from './icons/avs/a5.png'

import guy6 from './icons/avs/a6.png'
import guy7 from './icons/avs/a7.png'
import guy8 from './icons/avs/a8.png'
import guy9 from './icons/avs/a9.png'
import guy10 from './icons/avs/a10.png'

import guy11 from './icons/avs/a11.png'
import guy12 from './icons/avs/a12.png'
import guy13 from './icons/avs/a13.png'
import guy14 from './icons/avs/a14.png'
import guy15 from './icons/avs/a15.png'

import guy16 from './icons/avs/a16.png'
import guy17 from './icons/avs/a17.png'
import guy18 from './icons/avs/a18.png'
import guy19 from './icons/avs/a19.png'
import guy20 from './icons/avs/a20.png'



import noMarks from './icons/none.png'
import oneMarks from './icons/s1.png'
import twoMarks from './icons/s2.png'
import threeMarks from './icons/s3.png'
import bull from './icons/guy.png'
import rotatePic from './icons/rotate.png'
import cameraPic from './icons/camera.png'
import vs from './icons/vs.jpeg'
import bar from './icons/line.png'
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
// import { View, StyleSheet, Text } from 'react-native';
import { isMobile } from 'react-device-detect';
import { Hidden } from "@mui/material";

import { VerticalAlignBottom } from "@mui/icons-material";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { Container } from '@mui/material';


import carbon from './carbon.png'; // Path to your carbon background image
import fade from './fade.png'; // Path to your carbon background image

// Throw class
class Throw {
    constructor(id, d1, d2, d3, total, currentScore) {
        this.id = id;
        this.darts = { one: d1, two: d2, three: d3 };
        this.total = total;
        this.currentScore = currentScore;
    }

    getDartValueFromID(id) {
        if (!id) return 0;
        if (id === 'Bull') return 50;
        if (id === 'Outer') return 25;
        if (id === 'Miss') return 0;

        let mod = 1;
        switch (id[0]) {
            case 's': mod = 1; break;
            case 'd': mod = 2; break;
            case 't': mod = 3; break;
            default: mod = 1;
        }
        return mod * parseInt(id.substr(1));
    }

    calculateTotal() {
        this.total = [this.darts.one, this.darts.two, this.darts.three]
            .map(dart => this.getDartValueFromID(dart))
            .reduce((a, b) => a + b, 0);
    }

    calculateCurrentScore(prevCurrentScore, id) {
        this.currentScore = prevCurrentScore - this.getDartValueFromID(id);
        return this.currentScore;
    }

    calculateReverseScore(prevCurrentScore, id) {
        this.currentScore = prevCurrentScore + this.getDartValueFromID(id);
        return this.currentScore;
    }

    isThrowComplete() {
        return this.darts.three !== undefined;
    }



    addDart(id, currentScore) {
        let dart = '';
        if (this.darts.three === undefined) dart = 'three';
        if (this.darts.two === undefined) dart = 'two';
        if (this.darts.one === undefined) dart = 'one';

        this.darts[dart] = id;
        this.calculateTotal();

        //calculate average
        this.calculateCurrentScore(currentScore, id);
    }
}

// Player class
class Player {
    constructor(id) {
        this.id = id;
        this.currentScore = 701;
        this.lastScore = 701;
        this.average = "-";
        this.throws = [new Throw('-', '-', '-', '-', '-', this.currentScore)];
    }

    get currentThrow() {
        return this.throws[this.throws.length - 1];
    }

    addDart(id) {
        if (this.currentThrow.isThrowComplete()) {
            this.throws.push(new Throw(this.throws.length));
        }
        this.currentThrow.addDart(id, this.currentScore);
    }




}


class Game {
    constructor(gameType = 4) {
        this.gameType = gameType;
        this.startingScore = 701;
        this.rounds = 1;
        this.player = {};
        this.gameOver = false;
        this.playerBusted = false;

        // Initialize players based on gameType
        if (this.gameType === 2) {
            this.player.one = new Player(1);
            this.player.two = new Player(2);
            this.player.three = new Player(3);
            this.player.four = new Player(4);
        } else if (this.gameType === 4) {
            this.player.one = new Player(1);
            this.player.two = new Player(2);
            this.player.three = new Player(3);
            this.player.four = new Player(4);
        } else {
            console.error("Invalid game type. Defaulting to 4 players.");
            this.player.one = new Player(1);
            this.player.two = new Player(2);
            this.player.three = new Player(3);
            this.player.four = new Player(4);
        }

        this.currentPlayer = this.player.one;

    }

    switchPlayers() {
        if (this.currentPlayer.average == "-") {
            this.currentPlayer.average = 0;
        }

        if (this.gameType === 2) {
            if (this.currentPlayer.id === 1) {

                this.currentPlayer = this.player.two;

                this.player.one.average = Math.round(
                    ((this.startingScore - this.player.one.currentScore) / (this.rounds * 3)) * 100
                ) / 100;

            } else if (this.currentPlayer.id === 2) {
                this.currentPlayer = this.player.one;
                this.player.two.average = Math.round(
                    ((this.startingScore - this.player.two.currentScore) / (this.rounds * 3)) * 100
                ) / 100;

                this.rounds = this.rounds + 1;
            } else {
                console.error("Invalid player ID for a 2-player game");
            }
        } else if (this.gameType === 4) {
            switch (this.currentPlayer.id) {
                case 1:

                    this.currentPlayer = this.player.two;
                    this.player.one.average = Math.round(
                        ((this.startingScore - this.player.one.currentScore) / (this.rounds * 3)) * 100
                    ) / 100;

                    break;
                case 2:
                    this.currentPlayer = this.player.three;
                    this.player.two.average = Math.round(
                        ((this.startingScore - this.player.two.currentScore) / (this.rounds * 3)) * 100
                    ) / 100;

                    break;
                case 3:
                    this.currentPlayer = this.player.four;
                    this.player.three.average = Math.round(
                        ((this.startingScore - this.player.three.currentScore) / (this.rounds * 3)) * 100
                    ) / 100;
                    break;
                case 4:
                    this.currentPlayer = this.player.one;
                    this.player.four.average = Math.round(
                        ((this.startingScore - this.player.four.currentScore) / (this.rounds * 3)) * 100
                    ) / 100;
                    this.rounds = this.rounds + 1;
                    break;
                default:
                    console.error("Invalid player ID for a 4-player game");
                    break;
            }
        }
    }

    addDart(id) {
        let didBust = false;
        if (this.currentPlayer) {
            this.currentPlayer.addDart(id);
            this.currentPlayer.currentScore = this.currentPlayer.currentThrow.calculateCurrentScore(
                this.currentPlayer.currentScore,
                id
            );
            if (this.currentPlayer.currentScore === 0) {
                this.gameOver = true;
                this.currentPlayer.currentScore = "Winner!"
            } else if (this.currentPlayer.currentScore < 0) {
                this.currentPlayer.currentScore = this.currentPlayer.lastScore;
                this.playerBusted = true;
                this.switchPlayers();
                didBust = true;

            }
            if (!this.gameOver && didBust === false) {
                if (this.currentPlayer.currentThrow.isThrowComplete()) {
                    this.currentPlayer.lastScore = this.currentPlayer.currentScore
                    this.switchPlayers();
                }
            }
        } else {
            console.error("No current player is set");
        }
    }

    handleUndo() {
        this.switchPlayers();
    }
}






export default function Seven01() {

    const navigate = useNavigate();

    // useEffect(() => {
    //     // Scroll down by 50 pixels when the component mounts
    //     window.scrollBy(0, 30);
    //   }, []);

    const [isClicked, setIsClicked] = useState(false);
    const handleReturnClick = () => {
        navigate('/validUser575');
    };

    // Boolean to control the toast visibility
    const [showToast, setShowToast] = useState(false);

    const handleClick = () => {
        setShowToast(true); // Show the toast
    };

    const handleClose5 = (event, reason) => {
        if (reason === 'clickaway') {
            game.playerBusted = false;
            return;
        }
        game.playerBusted = false;
        setShowToast(false); // Hide the toast
    };




    const [showPlayers, setShowPlayers] = useState(true);
    const [game, setGame] = useState(new Game(4));
    const [scores, setScores] = useState({ player1: 701, player2: 701, player3: 701, player4: 701 });
    //averges 
    const [averages, setAverages] = useState({ player1: "-", player2: "-", player3: "-", player4: "-" });
    const [rounds, setRounds] = useState(1);
    const [differenes, setDifferences] = useState({ player1: 0, player2: 0, player3: 0, player4: 0 });


    const handleNewGame = () => {
        const newGame = new Game(4);
        setGame(newGame);

        // Set scores based on the value of showPlayers
        if (newGame.gameType === 4) {
            setScores({ player1: 701, player2: 701, player3: 701, player4: 701 });
            setAverages({ player1: "-", player2: "-", player3: "-", player4: "-" });
        } else {
            setScores({ player1: 701, player2: 701, player3: 701, player4: 701 });
            setAverages({ player1: "-", player2: "-", player3: "-", player4: "-" });
        }
        setOpen2();
    };


    const handleNewGame2 = () => {
        const newGame = new Game(4);
        setGame(newGame);

        // Set scores based on the value of showPlayers
        if (newGame.gameType === 4) {
            setScores({ player1: 701, player2: 701, player3: 701, player4: 701 });
            setAverages({ player1: "-", player2: "-", player3: "-", player4: "-" });
        } else {
            setScores({ player1: 701, player2: 701, player3: 701, player4: 701 });
            setAverages({ player1: "-", player2: "-", player3: "-", player4: "-" });
        }
        game.gameOver = false;
    };


    const handleGameSwap = () => {
        if (showPlayers) {
            // Create a 2-player game
            const newGame = new Game(2);
            setGame(newGame);
            setShowPlayers(false);
            // Set scores for 2 players
            setScores({ player1: 701, player2: 701 });
            setPlayText("Make 4 Player");
        } else {
            // Create a 4-player game
            const newGame = new Game(4);
            setGame(newGame);
            setShowPlayers(true);
            // Set scores for 4 players
            setScores({ player1: 701, player2: 701, player3: 701, player4: 701 });
            setPlayText("Make 2 Player");
        }

        setOpen();
    };



    const handleDartboardClick = useCallback((event) => {
        const dartId = event.target.getAttribute('id');
        if (dartId) {
            game.addDart(dartId);
            setScores({
                player1: game.player.one.currentScore,
                player2: game.player.two.currentScore,
                player3: game.player.three.currentScore,
                player4: game.player.four.currentScore

            });
        }

    }, [game]);



    const handleDartboardClick2 = () => {
        // const dartId = event.target.getAttribute('id');
        setIsClicked(true);
        setTimeout(() => {
            setIsClicked(false); // Revert back after 500 milliseconds
        }, 50);

        game.addDart("Miss");
        setScores({
            player1: game.player.one.currentScore,
            player2: game.player.two.currentScore,
            player3: game.player.three.currentScore,
            player4: game.player.four.currentScore

        });


    }




    useEffect(() => {
        const dartboardSections = document.querySelectorAll('#dartboard #areas g path, #dartboard #areas g circle');
        dartboardSections.forEach(section => section.addEventListener('click', handleDartboardClick));
        return () => {
            dartboardSections.forEach(section => section.removeEventListener('click', handleDartboardClick));
        };
    }, [handleDartboardClick]);

    const [isHidden, setIsHidden] = useState(true);


    const toggleImageVisibility = () => {
        setIsHidden(!isHidden);
    };

    const [img, setImg] = useState(null);
    const webcamRef = useRef(null);

    const videoConstraints = {
        width: { ideal: 185, },
        height: { ideal: 300 },
        facingMode: "user",
    };




    const videoConstraints5 = {
        width: { min: 600 },
        height: { min: 800 },
        facingMode: "user",
    };


    const capture = useCallback(() => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImg(imageSrc);
    }, [webcamRef]);


    //pic 2
    const [isHidden2, setIsHidden2] = useState(true);

    const toggleImageVisibility2 = () => {
        setIsHidden2(!isHidden2);
    };

    const [img2, setImg2] = useState(null);
    const webcamRef2 = useRef(null);

    const videoConstraints2 = {
        width: { min: 480 },
        height: { min: 690 },
        facingMode: "user",
    };

    const capture2 = useCallback(() => {
        const imageSrc2 = webcamRef2.current.getScreenshot();
        setImg2(imageSrc2);
    }, [webcamRef2]);

    //pic 3
    const [isHidden3, setIsHidden3] = useState(true);

    const toggleImageVisibility3 = () => {
        setIsHidden3(!isHidden3);
    };

    const [img3, setImg3] = useState(null);
    const webcamRef3 = useRef(null);

    const videoConstraints3 = {
        width: { min: 480 },
        height: { min: 690 },
        facingMode: "user",
    };

    const capture3 = useCallback(() => {
        const imageSrc3 = webcamRef3.current.getScreenshot();
        setImg3(imageSrc3);
    }, [webcamRef3]);

    //pic4
    const [isHidden4, setIsHidden4] = useState(true);


    const toggleImageVisibility4 = () => {
        setIsHidden4(!isHidden4);
    };


    const [img4, setImg4] = useState(null);
    const webcamRef4 = useRef(null);


    const videoConstraints4 = {
        width: { min: 480 },
        height: { min: 690 },
        facingMode: "user",
    };


    const capture4 = useCallback(() => {
        const imageSrc4 = webcamRef4.current.getScreenshot();
        setImg4(imageSrc4);
    }, [webcamRef4]);




    const [windowWidth, setWindowWidth] = useState(window.innerWidth)
    const [windowHeight, setWindowHeight] = useState(window.innerHeight)
    const setWindowDimensions = () => {
        setWindowWidth(window.innerWidth)
        setWindowHeight(window.innerHeight)
    }
    useEffect(() => {
        window.addEventListener('resize', setWindowDimensions);
        return () => {
            window.removeEventListener('resize', setWindowDimensions)
        }
    }, [])








    const [playerOnePic, setPlayerOnePic] = useState(guy1);

    const changeplayer1Icon = () => {
        // if(player1Value === 4) {
        //   setPlayer1Value(0);
        // } else {
        //   setPlayer1Value(player1Value + 1);
        // }


        if (isHidden) {
            if (playerOnePic === guy1) {
                setPlayerOnePic(guy2);
            } else if (playerOnePic === guy2) {
                setPlayerOnePic(guy3);
            } else if (playerOnePic === guy3) {
                setPlayerOnePic(guy4);
            } else if (playerOnePic === guy4) {
                setPlayerOnePic(guy5);
            } else if (playerOnePic === guy5) {
                setPlayerOnePic(guy6);
            } else if (playerOnePic === guy6) {
                setPlayerOnePic(guy7);
            } else if (playerOnePic === guy7) {
                setPlayerOnePic(guy8);
            } else if (playerOnePic === guy8) {
                setPlayerOnePic(guy9);
            } else if (playerOnePic === guy9) {
                setPlayerOnePic(guy10);
            } else if (playerOnePic === guy10) {
                setPlayerOnePic(guy11);
            } else if (playerOnePic === guy11) {
                setPlayerOnePic(guy12);
            } else if (playerOnePic === guy12) {
                setPlayerOnePic(guy13);
            } else if (playerOnePic === guy13) {
                setPlayerOnePic(guy14);
            } else if (playerOnePic === guy14) {
                setPlayerOnePic(guy15);
            } else if (playerOnePic === guy15) {
                setPlayerOnePic(guy16);
            } else if (playerOnePic === guy16) {
                setPlayerOnePic(guy17);
            } else if (playerOnePic === guy17) {
                setPlayerOnePic(guy18);
            } else if (playerOnePic === guy18) {
                setPlayerOnePic(guy19);
            } else if (playerOnePic === guy19) {
                setPlayerOnePic(guy20);
            } else if (playerOnePic === guy20) {
                setPlayerOnePic(guy1);
            }


        } else {
            setIsHidden(true);
        }

    }

    const [playerTwoPic, setPlayerTwoPic] = useState(guy6);
    const [playText, setPlayText] = useState('Make 2 Player');


    const changeplayer2Icon = () => {
        // if(player2Value === 4) {
        //   setPlayer2Value(0);
        // } else {
        //   setPlayer2Value(player2Value + 2);
        // }


        if (isHidden2) {
            if (playerTwoPic === guy1) {
                setPlayerTwoPic(guy2);
            } else if (playerTwoPic === guy2) {
                setPlayerTwoPic(guy3);
            } else if (playerTwoPic === guy3) {
                setPlayerTwoPic(guy4);
            } else if (playerTwoPic === guy4) {
                setPlayerTwoPic(guy5);
            } else if (playerTwoPic === guy5) {
                setPlayerTwoPic(guy6);
            } else if (playerTwoPic === guy6) {
                setPlayerTwoPic(guy7);
            } else if (playerTwoPic === guy7) {
                setPlayerTwoPic(guy8);
            } else if (playerTwoPic === guy8) {
                setPlayerTwoPic(guy9);
            } else if (playerTwoPic === guy9) {
                setPlayerTwoPic(guy10);
            } else if (playerTwoPic === guy10) {
                setPlayerTwoPic(guy11);
            } else if (playerTwoPic === guy11) {
                setPlayerTwoPic(guy12);
            } else if (playerTwoPic === guy12) {
                setPlayerTwoPic(guy13);
            } else if (playerTwoPic === guy13) {
                setPlayerTwoPic(guy14);
            } else if (playerTwoPic === guy14) {
                setPlayerTwoPic(guy15);
            } else if (playerTwoPic === guy15) {
                setPlayerTwoPic(guy16);
            } else if (playerTwoPic === guy16) {
                setPlayerTwoPic(guy17);
            } else if (playerTwoPic === guy17) {
                setPlayerTwoPic(guy18);
            } else if (playerTwoPic === guy18) {
                setPlayerTwoPic(guy19);
            } else if (playerTwoPic === guy19) {
                setPlayerTwoPic(guy20);
            } else if (playerTwoPic === guy20) {
                setPlayerTwoPic(guy1);
            }


        } else {
            setIsHidden2(true);
        }



    }

    const [playerThreePic, setPlayerThreePic] = useState(guy11);


    const changeplayer3Icon = () => {
        // if(player3Value === 4) {
        //   setPlayer3Value(0);
        // } else {
        //   setPlayer3Value(player3Value + 3);
        // }
        if (isHidden3) {
            if (playerThreePic === guy1) {
                setPlayerThreePic(guy2);
            } else if (playerThreePic === guy2) {
                setPlayerThreePic(guy3);
            } else if (playerThreePic === guy3) {
                setPlayerThreePic(guy4);
            } else if (playerThreePic === guy4) {
                setPlayerThreePic(guy5);
            } else if (playerThreePic === guy5) {
                setPlayerThreePic(guy6);
            } else if (playerThreePic === guy6) {
                setPlayerThreePic(guy7);
            } else if (playerThreePic === guy7) {
                setPlayerThreePic(guy8);
            } else if (playerThreePic === guy8) {
                setPlayerThreePic(guy9);
            } else if (playerThreePic === guy9) {
                setPlayerThreePic(guy10);
            } else if (playerThreePic === guy10) {
                setPlayerThreePic(guy11);
            } else if (playerThreePic === guy11) {
                setPlayerThreePic(guy12);
            } else if (playerThreePic === guy12) {
                setPlayerThreePic(guy13);
            } else if (playerThreePic === guy13) {
                setPlayerThreePic(guy14);
            } else if (playerThreePic === guy14) {
                setPlayerThreePic(guy15);
            } else if (playerThreePic === guy15) {
                setPlayerThreePic(guy16);
            } else if (playerThreePic === guy16) {
                setPlayerThreePic(guy17);
            } else if (playerThreePic === guy17) {
                setPlayerThreePic(guy18);
            } else if (playerThreePic === guy18) {
                setPlayerThreePic(guy19);
            } else if (playerThreePic === guy19) {
                setPlayerThreePic(guy20);
            } else if (playerThreePic === guy20) {
                setPlayerThreePic(guy1);
            }


        } else {
            setIsHidden3(true);
        }




    }

    const [playerFourPic, setPlayerFourPic] = useState(guy16);




    const changeplayer4Icon = () => {
        // if(player4Value === 4) {
        //   setPlayer4Value(0);
        // } else {
        //   setPlayer4Value(player4Value + 4);
        // }
        if (isHidden4) {
            if (playerFourPic === guy1) {
                setPlayerFourPic(guy2);
            } else if (playerFourPic === guy2) {
                setPlayerFourPic(guy3);
            } else if (playerFourPic === guy3) {
                setPlayerFourPic(guy4);
            } else if (playerFourPic === guy4) {
                setPlayerFourPic(guy5);
            } else if (playerFourPic === guy5) {
                setPlayerFourPic(guy6);
            } else if (playerFourPic === guy6) {
                setPlayerFourPic(guy7);
            } else if (playerFourPic === guy7) {
                setPlayerFourPic(guy8);
            } else if (playerFourPic === guy8) {
                setPlayerFourPic(guy9);
            } else if (playerFourPic === guy9) {
                setPlayerFourPic(guy10);
            } else if (playerFourPic === guy10) {
                setPlayerFourPic(guy11);
            } else if (playerFourPic === guy11) {
                setPlayerFourPic(guy12);
            } else if (playerFourPic === guy12) {
                setPlayerFourPic(guy13);
            } else if (playerFourPic === guy13) {
                setPlayerFourPic(guy14);
            } else if (playerFourPic === guy14) {
                setPlayerFourPic(guy15);
            } else if (playerFourPic === guy15) {
                setPlayerFourPic(guy16);
            } else if (playerFourPic === guy16) {
                setPlayerFourPic(guy17);
            } else if (playerFourPic === guy17) {
                setPlayerFourPic(guy18);
            } else if (playerFourPic === guy18) {
                setPlayerFourPic(guy19);
            } else if (playerFourPic === guy19) {
                setPlayerFourPic(guy20);
            } else if (playerFourPic === guy20) {
                setPlayerFourPic(guy1);
            }


        } else {
            setIsHidden4(true);
        }



    }



    const [checkedOne, setCheckedOne] = useState(false);
    const [checkedTwo, setCheckedTwo] = useState(false);
    const [checkedThree, setCheckedThree] = useState(false);



    const handleClose = () => {
        setOpen(false);
    };
    const [open, setOpen] = React.useState(false);

    const handleClose2 = () => {
        setOpen2(false);
    };
    const [open2, setOpen2] = React.useState(false);


    const handleClose3 = () => {
        setOpen3(false);
    };
    const [open3, setOpen3] = React.useState(false);

    const [play1Up, setplay1Up] = React.useState(true);
    const [play2Up, setplay2Up] = React.useState(false);
    const [play3Up, setplay3Up] = React.useState(false);
    const [play4Up, setplay4Up] = React.useState(false);



    return (


        <div >

            <Container
                style={{
                    backgroundImage: `url(${fade})`, // Use the carbon background image
                    backgroundSize: 'cover', // Ensure the background covers the entire area
                    backgroundPosition: 'center', // Center the background image
                    backgroundRepeat: 'no-repeat', // Do not repeat the background image

                    maxHeight: '300vh', // Ensure the background takes up the full height
                }}
            >


                <Snackbar open={game.playerBusted} autoHideDuration={3000} onClose={handleClose5}>
                    <Alert onClose={handleClose5} severity="error" sx={{ width: '100%' }}>
                        Player has busted
                    </Alert>
                </Snackbar>


                <div style={{ width: '100%', textAlign: 'left', marginTop: ".5%", marginBottom: "-3.5%" }}>
                    <Button
                        variant="contained"
                        color="error"
                        style={{
                            color: 'white', // Text color
                            width: '135px', // Larger width
                            height: '35px', // Larger height
                            fontSize: '.8rem', // Larger font size
                            padding: '5px', // Adjust padding if needed
                            marginTop: '1%',
                        }}
                        onClick={handleReturnClick}
                    >
                        RETURN HOME
                    </Button>
                </div>


                <Dialog
                    open={game.gameOver}>
                    <DialogTitle class="alertText" style={{ maxHeight: '5px' }}>

                        Game Over!
                    </DialogTitle>
                    <DialogContent>

                    </DialogContent>
                    <DialogActions style={{ justifyContent: "center", marginLeft: '20px', marginRight: '20px' }}>
                        <Button variant="contained" onClick={handleNewGame2}>
                            RESTART GAME
                        </Button>


                    </DialogActions>


                </Dialog>



                <Dialog
                    open={open}>
                    <DialogTitle class="alertText">{ }
                        Restart
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText class="alertBody">
                            Are you sure you want to restart the game with a different player amount?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose} >
                            CLOSE
                        </Button>
                        <Button variant="contained" onClick={handleGameSwap}>
                            RESTART
                        </Button>
                    </DialogActions>


                </Dialog>


                <Dialog
                    open={open2}>
                    <DialogTitle class="alertText">Restart Game
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText class="alertBody">
                            Are you sure you want to restart the game?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" onClick={handleClose2} >
                            CLOSE
                        </Button>
                        <Button variant="contained" onClick={handleNewGame}>
                            RESTART
                        </Button>
                    </DialogActions>


                </Dialog>


                {/* <button onClick={fixDart}>
                Undo
            </button>
           */}


                <div className="board">
                    <svg
                        id="dartboard"
                        xmlns="http://www.w3.org/2000/svg"
                        xmlnsXlink="http://www.w3.org/1999/xlink"
                        version="1.1"
                        x="0px"
                        y="0px"
                        width="100%"
                        height="100%"
                        viewBox="0 0 787 774"
                        enableBackground="new 0 0 787 774"
                        xmlSpace="preserve"
                    >
                        <g id="areas" class="whiteColor">
                            <g id="s">
                                <path id="s19" fill="#F7E9CD"
                                    d="M327.95 561.926c-1.952-0.825-3.879-1.693-5.795-2.581l66.1-138.906 c2.034 0.8 4.2 1.5 6.4 1.884L374.688 574.84C358.626 572.6 343 568.3 327.9 561.926z M358.728 696.9 l13.366-102.239c-17.849-2.479-35.233-7.257-51.936-14.321c-2.225-0.941-4.422-1.932-6.605-2.945l-44.31 93.1 c3.562 1.7 7.2 3.3 10.8 4.827C305.328 686.1 331.7 693.3 358.7 696.928z" />
                                <path id="s14"
                                    d="M215.161 362.688c2.248-16.062 6.555-31.707 12.914-46.739c0.825-1.952 1.693-3.879 2.581-5.796l138.907 66.1 c-0.828 2.034-1.463 4.165-1.884 6.372L215.161 362.688z M93.073 346.728l102.239 13.366c2.479-17.849 7.256-35.233 14.321-51.936 c0.941-2.225 1.932-4.422 2.945-6.605l-93.115-44.31c-1.667 3.562-3.29 7.15-4.828 10.8 C103.936 293.3 96.7 319.7 93.1 346.728z" />
                                <path id="s4" fill="#F7E9CD"
                                    d="M533.705 256.295c12.355 12.4 22.8 26.2 31.2 41.238l-135.129 73.5 c-1.18-1.886-2.539-3.646-4.054-5.26l105.77-111.661C532.237 254.9 533 255.6 533.7 256.295z M620.759 169.2 c-1.515-1.515-3.051-3.004-4.592-4.484l-70.903 74.852c0.869 0.8 1.7 1.7 2.6 2.5 c13.724 13.7 25.3 29.1 34.6 45.823l90.58-49.287C659.04 213.3 641.5 190 620.8 169.241z" />
                                <path id="s16" fill="#F7E9CD"
                                    d="M268.295 521.705c-12.355-12.355-22.796-26.175-31.207-41.238l135.129-73.528 c1.179 1.9 2.5 3.6 4.1 5.26l-105.77 111.661C269.763 523.1 269 522.4 268.3 521.705z M185.833 613.2 l70.903-74.852c-0.868-0.84-1.738-1.678-2.594-2.533c-13.724-13.725-25.313-29.081-34.638-45.823l-90.58 49.3 c14.036 25.4 31.5 48.7 52.3 69.437C182.756 610.3 184.3 611.8 185.8 613.243z" />
                                <path id="s9" fill="#F7E9CD"
                                    d="M232.372 306.542c8.066-16.491 18.461-31.617 31.017-45.137l111.66 105.8 c-1.425 1.691-2.689 3.522-3.766 5.471L232.372 306.542z M214.294 297.938c8.97-18.388 20.556-35.244 34.564-50.298 l-74.848-70.898c-21.495 22.955-39.214 48.724-52.84 76.883L214.294 297.938z" />
                                <path id="s7"
                                    d="M318.542 557.628c-16.491-8.065-31.616-18.461-45.137-31.017l105.769-111.66c1.691 1.4 3.5 2.7 5.5 3.8 L318.542 557.628z M309.939 575.706c-18.388-8.97-35.245-20.556-50.299-34.564l-70.898 74.8 c22.955 21.5 48.7 39.2 76.9 52.84L309.939 575.706z" />
                                <path id="s8"
                                    d="M228.074 462.051c-5.027-11.887-8.772-24.156-11.234-36.711l151.229-28.172c0.54 2.2 1.3 4.3 2.2 6.3 l-135.124 73.525C232.603 472.1 230.2 467.1 228.1 462.051z M127.005 535.812l90.584-49.29 c-2.885-5.424-5.546-10.981-7.956-16.681c-5.592-13.221-9.749-26.869-12.473-40.836L95.789 447.9 c4.072 21.2 10.3 42 18.8 62.082C118.373 518.8 122.5 527.4 127 535.812z" />
                                <path id="s12"
                                    d="M268.295 256.295c12.355-12.356 26.175-22.796 41.238-31.207l73.528 135.1 c-1.885 1.18-3.646 2.539-5.259 4.054l-111.662-105.77C266.855 257.8 267.6 257 268.3 256.295z M176.756 173.8 l74.853 70.903c0.839-0.868 1.677-1.738 2.533-2.594c13.724-13.724 29.08-25.313 45.823-34.639l-49.287-90.58 c-25.392 14.036-48.663 31.543-69.437 52.317C179.726 170.8 178.2 172.3 176.8 173.833z" />
                                <path id="s1" fill="#F7E9CD"
                                    d="M479.846 218.655l-66.101 138.907c-2.034-0.828-4.165-1.463-6.372-1.884l19.939-152.517 c16.062 2.2 31.7 6.6 46.7 12.914C476.002 216.9 477.9 217.8 479.8 218.655z M443.272 81.073l-13.365 102.2 c17.849 2.5 35.2 7.3 51.9 14.321c2.225 0.9 4.4 1.9 6.6 2.945l44.31-93.115c-3.562-1.667-7.15-3.29-10.786-4.828 C496.672 91.9 470.3 84.7 443.3 81.073z" />
                                <path id="s18"
                                    d="M483.458 220.372c16.491 8.1 31.6 18.5 45.1 31.017l-105.769 111.7 c-1.691-1.425-3.522-2.689-5.472-3.766L483.458 220.372z M492.061 202.294c18.388 9 35.2 20.6 50.3 34.564l70.898-74.847 c-22.955-21.496-48.725-39.215-76.883-52.84L492.061 202.294z" />
                                <path id="s20"
                                    d="M401 201.327c7.521 0 15 0.4 22.3 1.319l-19.938 152.518c-0.796-0.056-1.596-0.095-2.406-0.095 c-1.435 0-2.846 0.1-4.235 0.274l-28.171-151.236C379.217 202.3 390 201.3 401 201.327z M346.042 83.046l18.884 101.4 c11.825-2.069 23.87-3.111 36.074-3.111c8.396 0 16.7 0.5 24.9 1.484l13.367-102.25c-12.629-1.547-25.408-2.331-38.308-2.331 C382.4 78.2 364.1 79.8 346 83.046z" />
                                <path id="s5" fill="#F7E9CD"
                                    d="M327.95 216.074c11.887-5.028 24.156-8.772 36.711-11.234l28.17 151.2 c-2.177 0.54-4.271 1.29-6.26 2.229l-73.525-135.124C317.894 220.6 322.9 218.2 327.9 216.074z M254.187 115 l49.29 90.584c5.423-2.885 10.982-5.546 16.681-7.956c13.221-5.592 26.87-9.749 40.837-12.473L342.112 83.8 c-21.24 4.071-41.993 10.35-62.083 18.848C271.193 106.4 262.6 110.5 254.2 115.005z" />
                                <path id="s3"
                                    d="M401 576.674c-7.519 0-14.968-0.448-22.335-1.318l19.936-152.519c0.793 0.1 1.6 0.1 2.4 0.1 c1.432 0 2.84-0.1 4.226-0.272l28.165 151.237C422.772 575.7 412 576.7 401 576.674z M455.937 694.958l-18.88-101.377 c-11.819 2.066-23.858 3.108-36.057 3.108c-8.393 0-16.708-0.503-24.93-1.482l-13.365 102.2 c12.625 1.5 25.4 2.3 38.3 2.329C419.593 699.8 437.9 698.2 455.9 694.958z" />
                                <path id="s10"
                                    d="M586.84 415.312c-2.248 16.062-6.556 31.705-12.914 46.738c-0.825 1.951-1.693 3.879-2.581 5.8 l-138.906-66.101c0.828-2.034 1.463-4.166 1.884-6.373L586.84 415.312z M708.928 431.272l-102.239-13.365 c-2.479 17.849-7.257 35.231-14.321 51.935c-0.941 2.226-1.932 4.423-2.945 6.606l93.114 44.3 c1.667-3.562 3.289-7.15 4.827-10.786C698.064 484.7 705.3 458.3 708.9 431.272z" />
                                <path id="s17" fill="#F7E9CD"
                                    d="M474.051 561.926c-11.888 5.027-24.157 8.772-36.712 11.234l-28.171-151.229 c2.178-0.541 4.271-1.291 6.261-2.229l73.525 135.124C484.106 557.4 479.1 559.8 474.1 561.926z M547.812 663 l-49.29-90.584c-5.424 2.885-10.981 5.546-16.681 7.956c-13.222 5.592-26.87 9.748-40.837 12.473l18.883 101.4 c21.241-4.072 41.993-10.351 62.084-18.849C530.807 671.6 539.4 667.5 547.8 662.995z" />
                                <path id="s13"
                                    d="M573.926 315.95c5.027 11.9 8.8 24.2 11.2 36.711l-151.229 28.17c-0.541-2.177-1.291-4.271-2.229-6.26 l135.123-73.525C569.397 305.9 571.8 310.9 573.9 315.95z M674.995 242.188l-90.584 49.3 c2.885 5.4 5.5 11 8 16.681c5.592 13.2 9.7 26.9 12.5 40.837l101.372-18.883 c-4.072-21.24-10.351-41.992-18.849-62.083C683.627 259.2 679.5 250.6 675 242.188z" />
                                <path id="s2"
                                    d="M533.705 521.705c-12.355 12.355-26.175 22.797-41.238 31.207l-73.528-135.129c1.886-1.18 3.646-2.539 5.26-4.054 l111.661 105.77C535.145 520.2 534.4 521 533.7 521.705z M625.243 604.167l-74.852-70.902 c-0.84 0.868-1.678 1.738-2.533 2.594c-13.725 13.724-29.081 25.313-45.823 34.639l49.287 90.6 c25.391-14.035 48.662-31.543 69.437-52.317C622.273 607.2 623.8 605.7 625.2 604.167z" />
                                <path id="s6" fill="#F7E9CD"
                                    d="M434.658 384.765l151.235-28.172c1.847 10.6 2.8 21.4 2.8 32.4 c0 7.521-0.448 14.975-1.319 22.345l-152.518-19.938c0.056-0.796 0.095-1.596 0.095-2.406 C434.932 387.6 434.8 386.2 434.7 384.765z M605.578 352.926c2.068 11.8 3.1 23.9 3.1 36.1 c0 8.396-0.503 16.715-1.484 24.94l102.251 13.367c1.546-12.629 2.33-25.408 2.33-38.308c0-18.6-1.623-36.95-4.832-54.958 L605.578 352.926z" />
                                <path id="s11" fill="#F7E9CD"
                                    d="M95.046 443.96c-3.209-18.009-4.833-36.359-4.833-54.96c0-12.899 0.784-25.678 2.331-38.307 l102.25 13.367c-0.981 8.225-1.484 16.544-1.484 24.94c0 12.2 1 24.2 3.1 36.075L95.046 443.96z M367.069 389 c0-0.81 0.039-1.61 0.095-2.406l-152.518-19.938c-0.871 7.37-1.319 14.823-1.319 22.345c0 11 0.9 21.8 2.8 32.4 l151.236-28.173C367.169 391.8 367.1 390.4 367.1 389z" />
                                <path id="s15" fill="#F7E9CD"
                                    d="M569.628 471.458c-8.065 16.491-18.461 31.616-31.017 45.138L426.952 410.8 c1.425-1.691 2.688-3.522 3.766-5.471L569.628 471.458z M587.706 480.062c-8.97 18.388-20.556 35.244-34.564 50.299l74.847 70.9 c21.496-22.955 39.216-48.725 52.841-76.883L587.706 480.062z" />
                            </g>
                            <g id="d">
                                <path id="d2" fill="#ED3737"
                                    d="M622.88 610.88c-20.979 20.979-44.481 38.659-70.124 52.831l7.533 13.8 c30.7-16.899 54.398-35.119 78.62-60.443l-11.488-10.882C625.922 607.8 624.4 609.3 622.9 610.88z" />
                                <path id="d17" fill="#4F9962"
                                    d="M523.14 678.127c-20.291 8.582-41.251 14.923-62.702 19.034l2.886 15.5 c34.421-6.584 62.59-16.587 93.452-33.186l-7.529-13.838C540.768 670.2 532.1 674.4 523.1 678.127z" />
                                <path id="d15" fill="#4F9962"
                                    d="M630.166 603.32l11.494 10.888c23.974-25.555 40.883-50.205 56.096-81.778l-14.218-6.766 C669.777 554.1 651.9 580.1 630.2 603.32z" />
                                <path id="d6" fill="#4F9962"
                                    d="M725.388 330.608l-15.484 2.884c3.243 18.2 4.9 36.7 4.9 55.5 c0 13.03-0.792 25.939-2.355 38.696l15.643 2.045C732.446 395 731.6 365.1 725.4 330.608z" />
                                <path id="d14" fill="#ED3737"
                                    d="M111.874 266.859c1.555-3.676 3.195-7.305 4.881-10.906l-14.23-6.771 c-14.904 31.716-23.368 60.385-28.08 95.111l15.653 2.046C93.802 319 101.1 292.4 111.9 266.859z" />
                                <path id="d10" fill="#ED3737"
                                    d="M690.127 511.14c-1.555 3.677-3.195 7.306-4.882 10.907l14.229 6.8 c14.903-31.712 23.367-60.381 28.08-95.11l-15.652-2.046C708.198 459 700.9 485.6 690.1 511.14z" />
                                <path id="d11" fill="#4F9962"
                                    d="M87.214 389c0-13.03 0.792-25.938 2.355-38.696l-15.643-2.045 c-4.373 34.77-3.564 64.7 2.7 99.134l15.485-2.885C88.854 426.3 87.2 407.8 87.2 389z" />
                                <path id="d19_1_" fill="#4F9962"
                                    d="M278.859 678.127c-3.676-1.555-7.305-3.195-10.906-4.882l-6.771 14.2 c31.715 14.9 60.4 23.4 95.1 28.08l2.046-15.652C331.017 696.2 304.4 688.9 278.9 678.127z" />
                                <path id="d8" fill="#ED3737"
                                    d="M111.874 511.14c-8.583-20.291-14.923-41.249-19.034-62.701l-15.494 2.9 c6.585 34.4 16.6 62.6 33.2 93.45l13.837-7.529C119.825 528.8 115.6 520.1 111.9 511.14z" />
                                <path id="d16" fill="#4F9962"
                                    d="M179.12 610.88c-20.979-20.979-38.658-44.48-52.831-70.124l-13.844 7.5 c16.9 30.7 35.1 54.4 60.4 78.621l10.883-11.488C182.209 613.9 180.7 612.4 179.1 610.88z" />
                                <path id="d7" fill="#ED3737"
                                    d="M186.679 618.167l-10.888 11.494c25.558 24 50.2 40.9 81.8 56.095l6.765-14.217 C235.892 657.8 209.9 639.9 186.7 618.167z" />
                                <path id="d3" fill="#ED3737"
                                    d="M401 702.786c-13.026 0-25.93-0.792-38.684-2.354l-2.057 15.7 c14.603 1.8 28.2 2.8 41.6 2.828c0.001 0 0 0 0 0c18.557 0 37.397-1.848 57.523-5.491l-2.905-15.602 C438.306 701.1 419.8 702.8 401 702.786z" />
                                <path id="d1" fill="#4F9962"
                                    d="M523.14 99.874c3.677 1.6 7.3 3.2 10.9 4.882l6.771-14.23 c-31.719-14.905-60.387-23.369-95.11-28.079l-2.046 15.652C470.984 81.8 497.6 89.1 523.1 99.874z" />
                                <path id="d20" fill="#ED3737"
                                    d="M401 75.214c13.03 0 25.9 0.8 38.7 2.355l2.045-15.642 c-14.604-1.835-28.238-2.729-41.611-2.729c-18.558 0-37.395 1.772-57.521 5.416l2.884 15.5 C363.681 76.9 382.2 75.2 401 75.214z" />
                                <path id="d18" fill="#ED3737"
                                    d="M615.32 159.833l10.888-11.494c-25.557-23.974-50.207-40.882-81.778-56.095l-6.766 14.2 C566.107 120.2 592.1 138.1 615.3 159.833z" />
                                <path id="d5" fill="#4F9962"
                                    d="M278.859 99.874c20.292-8.583 41.251-14.923 62.703-19.034l-2.886-15.494 c-34.42 6.584-62.589 16.588-93.452 33.188l7.529 13.836C261.231 107.8 269.9 103.6 278.9 99.874z" />
                                <path id="d12" fill="#ED3737"
                                    d="M179.12 167.12c20.979-20.979 44.48-38.658 70.124-52.831l-7.533-13.843 c-30.701 16.9-54.399 35.119-78.621 60.442l11.489 10.882C176.078 170.2 177.6 168.7 179.1 167.12z" />
                                <path id="d9" fill="#4F9962"
                                    d="M171.833 174.679l-11.494-10.888c-23.975 25.557-40.883 50.207-56.095 81.779l14.218 6.8 C132.223 223.9 150.1 197.9 171.8 174.679z" />
                                <path id="d4" fill="#4F9962"
                                    d="M622.88 167.12c20.979 21 38.7 44.5 52.8 70.124l13.843-7.532 c-16.898-30.7-35.117-54.398-60.442-78.622l-10.883 11.489C619.791 164.1 621.3 165.6 622.9 167.12z" />
                                <path id="d13" fill="#ED3737"
                                    d="M690.127 266.859c8.582 20.3 14.9 41.3 19 62.703l15.494-2.886 c-6.587-34.424-16.591-62.592-33.188-93.451l-13.836 7.529C682.176 249.2 686.4 257.9 690.1 266.859z" />
                            </g>
                            <g id="t">
                                <path id="t10" fill="#ED3737"
                                    d="M603.714 417.519l-13.899-1.817c-2.282 16.329-6.661 32.233-13.125 47.5 c-0.843 1.992-1.729 3.96-2.636 5.917l12.659 6.023c0.994-2.145 1.967-4.301 2.891-6.485 C596.562 452.2 601.3 435.1 603.7 417.519z" />
                                <path id="t13" fill="#ED3737"
                                    d="M581.775 292.911l-12.314 6.701c2.618 4.9 5 10 7.2 15.2 c5.112 12.1 8.9 24.6 11.4 37.331l13.781-2.567c-2.685-13.755-6.779-27.197-12.287-40.217 C587.231 303.7 584.6 298.2 581.8 292.911z" />
                                <path id="t3" fill="#ED3737"
                                    d="M436.508 590.631l-2.567-13.784c-10.799 1.878-21.797 2.827-32.94 2.8 c-7.649 0-15.229-0.456-22.724-1.343l-1.817 13.9c8.094 1 16.3 1.5 24.5 1.5 C413.013 593.7 424.9 592.7 436.5 590.631z" />
                                <path id="t2" fill="#ED3737"
                                    d="M548.214 531.201l-10.177-9.639c-0.733 0.757-1.464 1.517-2.211 2.3 c-12.561 12.561-26.61 23.173-41.926 31.721l6.7 12.314c16.491-9.188 31.617-20.604 45.137-34.124 C546.574 532.9 547.4 532.1 548.2 531.201z" />
                                <path id="t8" fill="#ED3737"
                                    d="M220.225 485.089l12.315-6.701c-2.619-4.934-5.037-9.987-7.228-15.169 c-5.113-12.087-8.919-24.562-11.421-37.329l-13.781 2.566c2.685 13.8 6.8 27.2 12.3 40.2 C214.768 474.3 217.4 479.8 220.2 485.089z" />
                                <path id="t14" fill="#ED3737"
                                    d="M198.287 360.482l13.899 1.817c2.282-16.33 6.661-32.235 13.125-47.519 c0.843-1.992 1.729-3.959 2.635-5.916l-12.659-6.024c-0.995 2.145-1.967 4.301-2.891 6.5 C205.438 325.8 200.7 342.9 198.3 360.482z" />
                                <path id="t7" fill="#ED3737"
                                    d="M317.252 560.337c-16.775-8.201-32.16-18.774-45.91-31.548l-9.639 10.2 c14.825 13.8 31.4 25.2 49.5 34.033L317.252 560.337z" />
                                <path id="t9" fill="#4F9962"
                                    d="M229.663 305.252c8.202-16.776 18.775-32.161 31.548-45.911l-10.175-9.638 c-13.792 14.825-25.199 31.421-34.033 49.525L229.663 305.252z" />
                                <path id="t4" fill="#4F9962"
                                    d="M543.201 241.787l-9.64 10.176c0.758 0.7 1.5 1.5 2.3 2.2 c12.561 12.6 23.2 26.6 31.7 41.926l12.314-6.701c-9.188-16.491-20.604-31.617-34.124-45.136 C544.9 243.4 544 242.6 543.2 241.787z" />
                                <path id="t17" fill="#4F9962"
                                    d="M497.088 569.775l-6.7-12.314c-4.934 2.619-9.987 5.037-15.169 7.2 c-12.088 5.112-24.564 8.919-37.33 11.42l2.566 13.781c13.756-2.685 27.197-6.779 40.219-12.287 C486.281 575.2 491.8 572.6 497.1 569.775z" />
                                <path id="t15" fill="#4F9962"
                                    d="M572.337 472.748c-8.201 16.775-18.774 32.16-31.548 45.91l10.175 9.6 c13.792-14.825 25.199-31.422 34.033-49.525L572.337 472.748z" />
                                <path id="t18" fill="#ED3737"
                                    d="M490.771 205.003l-6.024 12.66c16.776 8.2 32.2 18.8 45.9 31.548l9.639-10.175 C525.472 225.2 508.9 213.8 490.8 205.003z" />
                                <path id="t20" fill="#ED3737"
                                    d="M365.476 187.372l2.567 13.784c10.804-1.88 21.808-2.83 32.957-2.83 c7.652 0 15.2 0.5 22.7 1.344l1.816-13.901c-8.097-0.964-16.285-1.459-24.551-1.459 C388.981 184.3 377.1 185.3 365.5 187.372z" />
                                <path id="t5" fill="#4F9962"
                                    d="M304.911 208.225l6.701 12.315c4.934-2.619 9.988-5.037 15.169-7.228 c12.087-5.113 24.564-8.919 37.331-11.421l-2.567-13.781c-13.755 2.685-27.197 6.779-40.218 12.3 C315.719 202.8 310.2 205.4 304.9 208.225z" />
                                <path id="t1" fill="#4F9962"
                                    d="M429.519 186.287l-1.817 13.899c16.33 2.3 32.2 6.7 47.5 13.1 c1.992 0.8 4 1.7 5.9 2.635l6.024-12.659c-2.145-0.995-4.301-1.967-6.485-2.891 C464.223 193.4 447.1 188.7 429.5 186.287z" />
                                <path id="t12" fill="#ED3737"
                                    d="M253.787 246.799l10.176 9.639c0.733-0.757 1.464-1.517 2.211-2.264 c12.56-12.561 26.61-23.173 41.926-31.721l-6.701-12.314c-16.491 9.188-31.617 20.605-45.136 34.1 C255.426 245.1 254.6 245.9 253.8 246.799z" />
                                <path id="t6" fill="#4F9962"
                                    d="M588.844 356.043c1.881 10.8 2.8 21.8 2.8 32.957c0 7.652-0.456 15.235-1.344 22.7 l13.9 1.816c0.964-8.097 1.459-16.286 1.459-24.551c0-12.019-1.026-23.88-3.062-35.524L588.844 356.043z" />
                                <path id="t11" fill="#4F9962"
                                    d="M213.157 421.958c-1.88-10.805-2.83-21.809-2.83-32.958c0-7.653 0.457-15.235 1.344-22.734 l-13.9-1.817c-0.964 8.097-1.459 16.286-1.459 24.551c0 12 1 23.9 3.1 35.525L213.157 421.958z" />
                                <path id="t19" fill="#4F9962"
                                    d="M372.482 591.714l1.817-13.899c-16.33-2.282-32.234-6.661-47.519-13.125 c-1.992-0.843-3.96-1.729-5.916-2.635l-6.023 12.658c2.144 1 4.3 2 6.5 2.9 C337.778 584.6 354.9 589.3 372.5 591.714z" />
                                <path id="t16" fill="#4F9962"
                                    d="M258.799 536.214l9.639-10.177c-0.757-0.733-1.517-1.464-2.264-2.211 c-12.561-12.56-23.173-26.609-31.721-41.926l-12.314 6.701c9.188 16.5 20.6 31.6 34.1 45.1 C257.1 534.6 257.9 535.4 258.8 536.214z" />
                            </g>
                            <g id="bull">
                                <path
                                    id="Outer"
                                    fill="#4F9962"
                                    d="M432.438 389c0 17.087-13.852 30.938-30.938 30.938S370.562 406.1 370.6 389 s13.852-30.938 30.938-30.938S432.438 371.9 432.4 389z M401.5 372.602c-9.057 0-16.398 7.342-16.398 16.4 s7.342 16.4 16.4 16.398s16.398-7.342 16.398-16.398S410.557 372.6 401.5 372.602z"
                                />
                                <circle id="Bull" fill="#ED3737" cx="401.5" cy="389" r="13.7" />
                            </g>



                        </g>

                        <g>

                            <path d="M389.1 49.01V53H375.93V49.67C376.95 48.85 377.92 48.06 378.84 47.3C379.76 46.52 380.79 45.61 381.93 44.57C382.49 44.07 382.9 43.53 383.16 42.95C383.42 42.37 383.55 41.82 383.55 41.3C383.55 40.76 383.42 40.34 383.16 40.04C382.94 39.82 381.78 39.71 379.68 39.71L377.67 39.74C377.41 39.76 377.09 39.77 376.71 39.77C376.65 39.49 376.58 38.94 376.5 38.12C376.44 37.3 376.41 36.69 376.41 36.29C378.67 35.89 380.55 35.69 382.05 35.69C384.09 35.69 385.71 36.08 386.91 36.86C388.13 37.62 388.74 38.94 388.74 40.82C388.74 41.72 388.51 42.65 388.05 43.61C387.59 44.55 386.69 45.54 385.35 46.58C384.45 47.28 383.27 48.11 381.81 49.07L389.1 49.01ZM398.37 35.69C400.03 35.69 401.41 35.91 402.51 36.35C403.61 36.77 404.48 37.63 405.12 38.93C405.76 40.21 406.08 42.08 406.08 44.54C406.08 46.9 405.77 48.73 405.15 50.03C404.53 51.31 403.66 52.19 402.54 52.67C401.44 53.15 400.06 53.39 398.4 53.39C396 53.39 394.16 52.76 392.88 51.5C391.6 50.22 390.96 48.03 390.96 44.93C390.96 41.81 391.58 39.49 392.82 37.97C394.06 36.45 395.91 35.69 398.37 35.69ZM398.55 49.52C399.55 49.52 400.2 49.13 400.5 48.35C400.8 47.55 400.95 46.3 400.95 44.6C400.95 42.86 400.77 41.58 400.41 40.76C400.05 39.92 399.37 39.5 398.37 39.5C397.67 39.5 397.2 39.62 396.96 39.86C396.78 40.06 396.62 40.66 396.48 41.66C396.36 42.66 396.3 43.68 396.3 44.72C396.3 46.48 396.47 47.72 396.81 48.44C397.17 49.16 397.75 49.52 398.55 49.52Z" fill="white" />
                            <path d="M180.57 102.9V120H175.32V107.22L171.6 108L171.03 104.85L176.34 102.9H180.57ZM196.76 116.01V120H183.59V116.67C184.61 115.85 185.58 115.06 186.5 114.3C187.42 113.52 188.45 112.61 189.59 111.57C190.15 111.07 190.56 110.53 190.82 109.95C191.08 109.37 191.21 108.82 191.21 108.3C191.21 107.76 191.08 107.34 190.82 107.04C190.6 106.82 189.44 106.71 187.34 106.71L185.33 106.74C185.07 106.76 184.75 106.77 184.37 106.77C184.31 106.49 184.24 105.94 184.16 105.12C184.1 104.3 184.07 103.69 184.07 103.29C186.33 102.89 188.21 102.69 189.71 102.69C191.75 102.69 193.37 103.08 194.57 103.86C195.79 104.62 196.4 105.94 196.4 107.82C196.4 108.72 196.17 109.65 195.71 110.61C195.25 111.55 194.35 112.54 193.01 113.58C192.11 114.28 190.93 115.11 189.47 116.07L196.76 116.01Z" fill="white" />
                            <path d="M287.98 62.32C290.02 62.32 291.52 62.84 292.48 63.88C293.44 64.9 293.92 66.22 293.92 67.84C293.92 71.52 291.37 73.36 286.27 73.36C284.63 73.36 282.76 73.2 280.66 72.88L281.32 69.58C282.98 69.76 284.22 69.85 285.04 69.85C286.26 69.85 287.19 69.68 287.83 69.34C288.47 69 288.79 68.42 288.79 67.6C288.79 67.32 288.74 67.03 288.64 66.73C288.56 66.41 288.43 66.21 288.25 66.13C288.17 66.09 287.95 66.05 287.59 66.01C287.25 65.97 286.84 65.95 286.36 65.95C284.88 65.95 283.43 66.07 282.01 66.31L281.86 66.16L283.09 55.9H293.23V59.89L286.81 59.86L286.48 62.41C287.02 62.35 287.52 62.32 287.98 62.32Z" fill="white" />
                            <path d="M115.95 183.69C118.47 183.69 120.3 184.39 121.44 185.79C122.6 187.19 123.18 189.31 123.18 192.15C123.18 195.19 122.52 197.48 121.2 199.02C119.88 200.56 118.01 201.33 115.59 201.33C114.61 201.33 113.69 201.28 112.83 201.18C111.97 201.08 111.3 200.95 110.82 200.79L111.39 197.61C112.47 197.69 113.23 197.73 113.67 197.73C115.09 197.73 116.16 197.48 116.88 196.98C117.62 196.46 118.05 195.51 118.17 194.13C117.57 194.47 116.93 194.73 116.25 194.91C115.57 195.09 114.96 195.18 114.42 195.18C112.32 195.18 110.9 194.61 110.16 193.47C109.44 192.33 109.08 191.08 109.08 189.72C109.08 187.88 109.64 186.42 110.76 185.34C111.9 184.24 113.63 183.69 115.95 183.69ZM118.05 191.31C118.09 190.95 118.11 190.66 118.11 190.44C118.11 189.6 117.94 188.88 117.6 188.28C117.26 187.68 116.75 187.38 116.07 187.38C114.71 187.38 114.03 188.14 114.03 189.66C114.03 190.36 114.16 190.89 114.42 191.25C114.6 191.45 115.1 191.55 115.92 191.55C116.66 191.55 117.37 191.47 118.05 191.31Z" fill="white" />
                            <path d="M60.57 280.9V298H55.32V285.22L51.6 286L51.03 282.85L56.34 280.9H60.57ZM78.1102 294.46H75.5302V298H70.2802V294.46H63.8902L62.8702 291.4C63.7302 289.84 64.8502 288.1 66.2302 286.18C67.6102 284.26 68.9602 282.5 70.2802 280.9H75.5302V290.98H78.1102V294.46ZM70.3702 285.67C69.2302 287.31 68.0802 289.08 66.9202 290.98H70.3702V285.67Z" fill="white" />
                            <path d="M50.57 394.9V412H45.32V399.22L41.6 400L41.03 396.85L46.34 394.9H50.57ZM62.2302 394.9V412H56.9802V399.22L53.2602 400L52.6902 396.85L58.0002 394.9H62.2302Z" fill="white" />
                            <path d="M78.64 505.55C79.92 506.13 80.85 506.81 81.43 507.59C82.01 508.35 82.3 509.26 82.3 510.32C82.3 511.94 81.6 513.18 80.2 514.04C78.82 514.9 77.09 515.33 75.01 515.33C72.95 515.33 71.27 514.97 69.97 514.25C68.69 513.51 68.05 512.41 68.05 510.95C68.05 510.13 68.35 509.32 68.95 508.52C69.57 507.72 70.44 507.07 71.56 506.57C70.6 506.01 69.86 505.34 69.34 504.56C68.84 503.76 68.59 502.98 68.59 502.22C68.59 500.72 69.23 499.59 70.51 498.83C71.79 498.07 73.37 497.69 75.25 497.69C79.47 497.69 81.58 499.07 81.58 501.83C81.58 503.11 80.6 504.35 78.64 505.55ZM75.28 500.6C74.7 500.6 74.26 500.74 73.96 501.02C73.68 501.28 73.54 501.69 73.54 502.25C73.54 502.75 73.75 503.17 74.17 503.51C74.61 503.83 75.2 504.14 75.94 504.44C76.92 503.76 77.41 503.06 77.41 502.34C77.41 501.74 77.23 501.3 76.87 501.02C76.53 500.74 76 500.6 75.28 500.6ZM75.07 512.45C75.61 512.45 76.08 512.3 76.48 512C76.88 511.68 77.08 511.24 77.08 510.68C77.08 510.14 76.88 509.7 76.48 509.36C76.1 509 75.46 508.6 74.56 508.16C74.02 508.54 73.59 508.93 73.27 509.33C72.97 509.73 72.82 510.18 72.82 510.68C72.82 511.22 73.05 511.65 73.51 511.97C73.99 512.29 74.51 512.45 75.07 512.45Z" fill="white" />
                            <path d="M114.57 591.9V609H109.32V596.22L105.6 597L105.03 593.85L110.34 591.9H114.57ZM126.32 597.9C128.42 597.9 129.83 598.47 130.55 599.61C131.29 600.75 131.66 602 131.66 603.36C131.66 605.2 131.09 606.67 129.95 607.77C128.83 608.85 127.11 609.39 124.79 609.39C122.27 609.39 120.43 608.69 119.27 607.29C118.13 605.89 117.56 603.77 117.56 600.93C117.56 597.89 118.22 595.6 119.54 594.06C120.86 592.52 122.73 591.75 125.15 591.75C126.13 591.75 127.05 591.8 127.91 591.9C128.77 592 129.44 592.13 129.92 592.29L129.35 595.47C128.27 595.39 127.51 595.35 127.07 595.35C125.65 595.35 124.57 595.61 123.83 596.13C123.11 596.63 122.69 597.57 122.57 598.95C123.17 598.61 123.81 598.35 124.49 598.17C125.17 597.99 125.78 597.9 126.32 597.9ZM124.67 605.7C126.03 605.7 126.71 604.94 126.71 603.42C126.71 602.72 126.58 602.19 126.32 601.83C126.14 601.63 125.64 601.53 124.82 601.53C124.08 601.53 123.37 601.61 122.69 601.77C122.65 602.13 122.63 602.42 122.63 602.64C122.63 603.48 122.8 604.2 123.14 604.8C123.48 605.4 123.99 605.7 124.67 605.7Z" fill="white" />
                            <path d="M208.65 668.9V672.11C207.47 674.25 206.28 676.6 205.08 679.16C203.88 681.72 202.93 684.09 202.23 686.27L197.34 685.19C198.78 681.21 200.83 677.09 203.49 672.83H195.36V668.9H208.65Z" fill="white" />
                            <path d="M620.1 669.01V673H606.93V669.67C607.95 668.85 608.92 668.06 609.84 667.3C610.76 666.52 611.79 665.61 612.93 664.57C613.49 664.07 613.9 663.53 614.16 662.95C614.42 662.37 614.55 661.82 614.55 661.3C614.55 660.76 614.42 660.34 614.16 660.04C613.94 659.82 612.78 659.71 610.68 659.71L608.67 659.74C608.41 659.76 608.09 659.77 607.71 659.77C607.65 659.49 607.58 658.94 607.5 658.12C607.44 657.3 607.41 656.69 607.41 656.29C609.67 655.89 611.55 655.69 613.05 655.69C615.09 655.69 616.71 656.08 617.91 656.86C619.13 657.62 619.74 658.94 619.74 660.82C619.74 661.72 619.51 662.65 619.05 663.61C618.59 664.55 617.69 665.54 616.35 666.58C615.45 667.28 614.27 668.11 612.81 669.07L620.1 669.01Z" fill="white" />
                            <path d="M690.57 580.9V598H685.32V585.22L681.6 586L681.03 582.85L686.34 580.9H690.57ZM700.64 587.32C702.68 587.32 704.18 587.84 705.14 588.88C706.1 589.9 706.58 591.22 706.58 592.84C706.58 596.52 704.03 598.36 698.93 598.36C697.29 598.36 695.42 598.2 693.32 597.88L693.98 594.58C695.64 594.76 696.88 594.85 697.7 594.85C698.92 594.85 699.85 594.68 700.49 594.34C701.13 594 701.45 593.42 701.45 592.6C701.45 592.32 701.4 592.03 701.3 591.73C701.22 591.41 701.09 591.21 700.91 591.13C700.83 591.09 700.61 591.05 700.25 591.01C699.91 590.97 699.5 590.95 699.02 590.95C697.54 590.95 696.09 591.07 694.67 591.31L694.52 591.16L695.75 580.9H705.89V584.89L699.47 584.86L699.14 587.41C699.68 587.35 700.18 587.32 700.64 587.32Z" fill="white" />
                            <path d="M734.57 481.9V499H729.32V486.22L725.6 487L725.03 483.85L730.34 481.9H734.57ZM745.03 481.69C746.69 481.69 748.07 481.91 749.17 482.35C750.27 482.77 751.14 483.63 751.78 484.93C752.42 486.21 752.74 488.08 752.74 490.54C752.74 492.9 752.43 494.73 751.81 496.03C751.19 497.31 750.32 498.19 749.2 498.67C748.1 499.15 746.72 499.39 745.06 499.39C742.66 499.39 740.82 498.76 739.54 497.5C738.26 496.22 737.62 494.03 737.62 490.93C737.62 487.81 738.24 485.49 739.48 483.97C740.72 482.45 742.57 481.69 745.03 481.69ZM745.21 495.52C746.21 495.52 746.86 495.13 747.16 494.35C747.46 493.55 747.61 492.3 747.61 490.6C747.61 488.86 747.43 487.58 747.07 486.76C746.71 485.92 746.03 485.5 745.03 485.5C744.33 485.5 743.86 485.62 743.62 485.86C743.44 486.06 743.28 486.66 743.14 487.66C743.02 488.66 742.96 489.68 742.96 490.72C742.96 492.48 743.13 493.72 743.47 494.44C743.83 495.16 744.41 495.52 745.21 495.52Z" fill="white" />
                            <path d="M746.66 380.9C748.76 380.9 750.17 381.47 750.89 382.61C751.63 383.75 752 385 752 386.36C752 388.2 751.43 389.67 750.29 390.77C749.17 391.85 747.45 392.39 745.13 392.39C742.61 392.39 740.77 391.69 739.61 390.29C738.47 388.89 737.9 386.77 737.9 383.93C737.9 380.89 738.56 378.6 739.88 377.06C741.2 375.52 743.07 374.75 745.49 374.75C746.47 374.75 747.39 374.8 748.25 374.9C749.11 375 749.78 375.13 750.26 375.29L749.69 378.47C748.61 378.39 747.85 378.35 747.41 378.35C745.99 378.35 744.91 378.61 744.17 379.13C743.45 379.63 743.03 380.57 742.91 381.95C743.51 381.61 744.15 381.35 744.83 381.17C745.51 380.99 746.12 380.9 746.66 380.9ZM745.01 388.7C746.37 388.7 747.05 387.94 747.05 386.42C747.05 385.72 746.92 385.19 746.66 384.83C746.48 384.63 745.98 384.53 745.16 384.53C744.42 384.53 743.71 384.61 743.03 384.77C742.99 385.13 742.97 385.42 742.97 385.64C742.97 386.48 743.14 387.2 743.48 387.8C743.82 388.4 744.33 388.7 745.01 388.7Z" fill="white" />
                            <path d="M729.57 267.9V285H724.32V272.22L720.6 273L720.03 269.85L725.34 267.9H729.57ZM741.05 276.27C742.47 276.37 743.49 276.77 744.11 277.47C744.73 278.17 745.04 279.04 745.04 280.08C745.04 281.58 744.48 282.84 743.36 283.86C742.24 284.86 740.45 285.36 737.99 285.36C736.33 285.36 734.48 285.2 732.44 284.88L733.04 281.58C734.84 281.66 736.02 281.7 736.58 281.7C737.68 281.7 738.48 281.56 738.98 281.28C739.5 281 739.76 280.45 739.76 279.63C739.76 279.05 739.57 278.62 739.19 278.34C738.83 278.04 738.31 277.85 737.63 277.77C736.95 277.69 735.98 277.65 734.72 277.65V274.86C735.98 274.86 736.93 274.83 737.57 274.77C738.23 274.69 738.73 274.52 739.07 274.26C739.43 274 739.61 273.59 739.61 273.03C739.61 272.55 739.6 272.17 739.58 271.89C739.56 271.61 739.49 271.43 739.37 271.35C739.21 271.25 738.5 271.2 737.24 271.2C736.1 271.2 734.71 271.24 733.07 271.32L732.77 268.14C734.95 267.84 736.7 267.69 738.02 267.69C739.94 267.69 741.51 268.06 742.73 268.8C743.97 269.54 744.59 270.74 744.59 272.4C744.59 273.24 744.31 274.04 743.75 274.8C743.21 275.54 742.31 276 741.05 276.18V276.27Z" fill="white" />
                            <path d="M687.45 188.46H684.87V192H679.62V188.46H673.23L672.21 185.4C673.07 183.84 674.19 182.1 675.57 180.18C676.95 178.26 678.3 176.5 679.62 174.9H684.87V184.98H687.45V188.46ZM679.71 179.67C678.57 181.31 677.42 183.08 676.26 184.98H679.71V179.67Z" fill="white" />
                            <path d="M600.57 94.9V112H595.32V99.22L591.6 100L591.03 96.85L596.34 94.9H600.57ZM614.3 102.55C615.58 103.13 616.51 103.81 617.09 104.59C617.67 105.35 617.96 106.26 617.96 107.32C617.96 108.94 617.26 110.18 615.86 111.04C614.48 111.9 612.75 112.33 610.67 112.33C608.61 112.33 606.93 111.97 605.63 111.25C604.35 110.51 603.71 109.41 603.71 107.95C603.71 107.13 604.01 106.32 604.61 105.52C605.23 104.72 606.1 104.07 607.22 103.57C606.26 103.01 605.52 102.34 605 101.56C604.5 100.76 604.25 99.98 604.25 99.22C604.25 97.72 604.89 96.59 606.17 95.83C607.45 95.07 609.03 94.69 610.91 94.69C615.13 94.69 617.24 96.07 617.24 98.83C617.24 100.11 616.26 101.35 614.3 102.55ZM610.94 97.6C610.36 97.6 609.92 97.74 609.62 98.02C609.34 98.28 609.2 98.69 609.2 99.25C609.2 99.75 609.41 100.17 609.83 100.51C610.27 100.83 610.86 101.14 611.6 101.44C612.58 100.76 613.07 100.06 613.07 99.34C613.07 98.74 612.89 98.3 612.53 98.02C612.19 97.74 611.66 97.6 610.94 97.6ZM610.73 109.45C611.27 109.45 611.74 109.3 612.14 109C612.54 108.68 612.74 108.24 612.74 107.68C612.74 107.14 612.54 106.7 612.14 106.36C611.76 106 611.12 105.6 610.22 105.16C609.68 105.54 609.25 105.93 608.93 106.33C608.63 106.73 608.48 107.18 608.48 107.68C608.48 108.22 608.71 108.65 609.17 108.97C609.65 109.29 610.17 109.45 610.73 109.45Z" fill="white" />
                            <path d="M503.57 49.9V67H498.32V54.22L494.6 55L494.03 51.85L499.34 49.9H503.57Z" fill="white" />
                            <path d="M296.57 713.9V731H291.32V718.22L287.6 719L287.03 715.85L292.34 713.9H296.57ZM306.61 713.69C309.13 713.69 310.96 714.39 312.1 715.79C313.26 717.19 313.84 719.31 313.84 722.15C313.84 725.19 313.18 727.48 311.86 729.02C310.54 730.56 308.67 731.33 306.25 731.33C305.27 731.33 304.35 731.28 303.49 731.18C302.63 731.08 301.96 730.95 301.48 730.79L302.05 727.61C303.13 727.69 303.89 727.73 304.33 727.73C305.75 727.73 306.82 727.48 307.54 726.98C308.28 726.46 308.71 725.51 308.83 724.13C308.23 724.47 307.59 724.73 306.91 724.91C306.23 725.09 305.62 725.18 305.08 725.18C302.98 725.18 301.56 724.61 300.82 723.47C300.1 722.33 299.74 721.08 299.74 719.72C299.74 717.88 300.3 716.42 301.42 715.34C302.56 714.24 304.29 713.69 306.61 713.69ZM308.71 721.31C308.75 720.95 308.77 720.66 308.77 720.44C308.77 719.6 308.6 718.88 308.26 718.28C307.92 717.68 307.41 717.38 306.73 717.38C305.37 717.38 304.69 718.14 304.69 719.66C304.69 720.36 304.82 720.89 305.08 721.25C305.26 721.45 305.76 721.55 306.58 721.55C307.32 721.55 308.03 721.47 308.71 721.31Z" fill="white" />
                            <path d="M410.39 736.27C411.81 736.37 412.83 736.77 413.45 737.47C414.07 738.17 414.38 739.04 414.38 740.08C414.38 741.58 413.82 742.84 412.7 743.86C411.58 744.86 409.79 745.36 407.33 745.36C405.67 745.36 403.82 745.2 401.78 744.88L402.38 741.58C404.18 741.66 405.36 741.7 405.92 741.7C407.02 741.7 407.82 741.56 408.32 741.28C408.84 741 409.1 740.45 409.1 739.63C409.1 739.05 408.91 738.62 408.53 738.34C408.17 738.04 407.65 737.85 406.97 737.77C406.29 737.69 405.32 737.65 404.06 737.65V734.86C405.32 734.86 406.27 734.83 406.91 734.77C407.57 734.69 408.07 734.52 408.41 734.26C408.77 734 408.95 733.59 408.95 733.03C408.95 732.55 408.94 732.17 408.92 731.89C408.9 731.61 408.83 731.43 408.71 731.35C408.55 731.25 407.84 731.2 406.58 731.2C405.44 731.2 404.05 731.24 402.41 731.32L402.11 728.14C404.29 727.84 406.04 727.69 407.36 727.69C409.28 727.69 410.85 728.06 412.07 728.8C413.31 729.54 413.93 730.74 413.93 732.4C413.93 733.24 413.65 734.04 413.09 734.8C412.55 735.54 411.65 736 410.39 736.18V736.27Z" fill="white" />
                            <path d="M513.57 709.9V727H508.32V714.22L504.6 715L504.03 711.85L509.34 709.9H513.57ZM529.31 709.9V713.11C528.13 715.25 526.94 717.6 525.74 720.16C524.54 722.72 523.59 725.09 522.89 727.27L518 726.19C519.44 722.21 521.49 718.09 524.15 713.83H516.02V709.9H529.31Z" fill="white" />

                        </g>


                    </svg>


                </div>



                <div className="buttonDiv">

                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                        <img
                            src={require('./miss.png')}
                            alt="Miss"
                            style={{
                                marginLeft: "10px",
                                marginTop: '10px',
                                marginBottom: "20px",
                                cursor: 'pointer',
                                width: '110px',
                                borderRadius: '50px',
                                backgroundColor: isClicked ? 'black' : 'transparent', // Black background when clicked
                                filter: isClicked ? 'brightness(0)' : 'none', // Blackout effect
                                transition: 'filter 0s', // Instant blackout effect
                            }}
                            onClick={handleDartboardClick2}
                        />

                        <Button variant="contained" onClick={setOpen} style={{ marginBottom: "10px", height: '40px' }}>
                            {playText}
                        </Button>
                    </div>





                    <Button variant="contained" onClick={setOpen2} style={{ marginRight: '-1.5%', marginTop: "140px", maxHeight: '40px' }}>
                        Restart Game
                    </Button>





                </div>
            </Container>
            <div className="scoreboard">
                <table>
                    <thead>
                        <tr>
                            <th>Current Player</th>
                            <th>Average</th>
                            <th>Remaining</th>
                        </tr>
                    </thead>

                    {game.currentPlayer.id === 1 && (
                        <tbody>
                            <tr id="player1-row" style={{ backgroundColor: "#b9db92" }}>
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerOnePic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg(null)}
                                                        >
                                                            Retake
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "57.5%", height: "auto", marginBottom: "22.5%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer1Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.one.average}</td>
                                <td className={'active'}>{scores.player1}</td>
                            </tr>


                            <tr id="player2-row">
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden2 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerTwoPic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img2 === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef2}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture2}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img2}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg2(null)}
                                                        >
                                                            Retake
                                                        </Button>

                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer2Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility2}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.two.average}</td>
                                <td className={'active'}>{scores.player2}</td>
                            </tr>
                            {showPlayers && (
                                <>
                                    <tr id="player3-row">
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden3 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerThreePic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img3 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef3}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture3}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img3}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg3(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer3Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility3}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>
                                    <tr id="player4-row">
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden4 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerFourPic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img4 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef4}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture4}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img4}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg4(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer4Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility4}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>
                                </>
                            )}
                        </tbody>
                    )}

                    {game.currentPlayer.id === 2 && (
                        <tbody>



                            <tr id="player2-row" style={{ backgroundColor: "#b9db92" }}>
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden2 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerTwoPic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img2 === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef2}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture2}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img2}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg2(null)}
                                                        >
                                                            Retake
                                                        </Button>

                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer2Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility2}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.two.average}</td>
                                <td className={'active'}>{scores.player2}</td>
                            </tr>
                            {showPlayers && (
                                <>
                                    <tr id="player3-row">
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden3 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerThreePic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img3 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef3}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture3}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img3}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg3(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer3Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility3}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>
                                    <tr id="player4-row">
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden4 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerFourPic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img4 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef4}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture4}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img4}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg4(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer4Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility4}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>

                                </>


                            )}

                            <tr id="player1-row" >
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerOnePic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg(null)}
                                                        >
                                                            Retake
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "57.5%", height: "auto", marginBottom: "22.5%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer1Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.one.average}</td>
                                <td className={'active'}>{scores.player1}</td>
                            </tr>
                        </tbody>
                    )}


                    {game.currentPlayer.id === 3 && (
                        <tbody>




                            {showPlayers && (
                                <>
                                    <tr id="player3-row" style={{ backgroundColor: "#b9db92" }}>
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden3 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerThreePic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img3 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef3}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture3}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img3}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg3(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer3Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility3}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>
                                    <tr id="player4-row">
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden4 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerFourPic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img4 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef4}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture4}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img4}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg4(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer4Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility4}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>


                                </>


                            )}

                            <tr id="player1-row" >
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerOnePic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg(null)}
                                                        >
                                                            Retake
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "57.5%", height: "auto", marginBottom: "22.5%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer1Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.one.average}</td>
                                <td className={'active'}>{scores.player1}</td>
                            </tr>
                            <tr id="player2-row">
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden2 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerTwoPic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img2 === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef2}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture2}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img2}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg2(null)}
                                                        >
                                                            Retake
                                                        </Button>

                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer2Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility2}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.two.average}</td>
                                <td className={'active'}>{scores.player2}</td>
                            </tr>
                        </tbody>
                    )}


                    {game.currentPlayer.id === 4 && (
                        <tbody>




                            {showPlayers && (
                                <>

                                    <tr id="player4-row" style={{ backgroundColor: "#b9db92" }}>
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden4 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerFourPic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img4 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef4}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture4}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img4}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg4(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer4Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility4}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>
                                    <tr id="player3-row" >
                                        <td style={{ maxWidth: "20%" }}>
                                            <div style={{ display: "flex", width: "100%", }}>

                                                {isHidden3 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerThreePic}></img> : (
                                                    <div style={{ width: "100%" }} >
                                                        {img3 === null ? (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <Webcam
                                                                        audio={false}
                                                                        facingMode="user"
                                                                        height='30%'
                                                                        width='100%'
                                                                        videoConstraints={videoConstraints}
                                                                        ref={webcamRef3}
                                                                        screenshotFormat="image/jpeg"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                                    variant="contained"
                                                                    color="success"
                                                                    onClick={capture3}
                                                                >
                                                                    Take photo
                                                                </Button>
                                                            </div>
                                                        ) : (
                                                            <div style={{ maxHeight: "250px" }}>
                                                                <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                                    <img
                                                                        height='30%'
                                                                        src={img3}
                                                                        alt="screenshot"
                                                                    />
                                                                </div>
                                                                <Button
                                                                    variant="contained"
                                                                    color="error"
                                                                    style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                                    onClick={() => setImg3(null)}
                                                                >
                                                                    Retake
                                                                </Button>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}



                                                {/* Button section on the right */}
                                                <div style={{ width: "25%" }}>
                                                    <img
                                                        style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                        src={rotatePic}
                                                        onClick={changeplayer3Icon}
                                                        className="rotButton"
                                                    />
                                                    <img
                                                        style={{ width: "57.5%", height: "auto", }}
                                                        src={cameraPic}
                                                        onClick={toggleImageVisibility3}
                                                        className="rotButton"
                                                    />
                                                </div>
                                            </div>

                                        </td>
                                        <td className={'active'}>{game.player.three.average}</td>
                                        <td className={'active'}>{scores.player3}</td>
                                    </tr>

                                </>


                            )}

                            <tr id="player1-row" >
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerOnePic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg(null)}
                                                        >
                                                            Retake
                                                        </Button>
                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "57.5%", height: "auto", marginBottom: "22.5%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer1Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.one.average}</td>
                                <td className={'active'}>{scores.player1}</td>
                            </tr>
                            <tr id="player2-row">
                                <td style={{ maxWidth: "20%" }}>
                                    <div style={{ display: "flex", width: "100%", }}>

                                        {isHidden2 ? <img style={{ width: "30%", maxHeight: "200px", marginBottom: '0%' }} src={playerTwoPic}></img> : (
                                            <div style={{ width: "100%" }} >
                                                {img2 === null ? (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <Webcam
                                                                audio={false}
                                                                facingMode="user"
                                                                height='30%'
                                                                width='100%'
                                                                videoConstraints={videoConstraints}
                                                                ref={webcamRef2}
                                                                screenshotFormat="image/jpeg"
                                                            />
                                                        </div>
                                                        <Button
                                                            style={{ width: "102.5%", marginTop: "-50px", marginLeft: '-3%' }}
                                                            variant="contained"
                                                            color="success"
                                                            onClick={capture2}
                                                        >
                                                            Take photo
                                                        </Button>
                                                    </div>
                                                ) : (
                                                    <div style={{ maxHeight: "250px" }}>
                                                        <div style={{ marginTop: "-2%", marginLeft: '-2%', marginBottom: "0%" }}>
                                                            <img
                                                                height='30%'
                                                                width='100%'
                                                                src={img2}
                                                                alt="screenshot"
                                                            />
                                                        </div>
                                                        <Button
                                                            variant="contained"
                                                            color="error"
                                                            style={{ width: "102.5%", marginTop: "-40px", marginLeft: '-2.5%' }}
                                                            onClick={() => setImg2(null)}
                                                        >
                                                            Retake
                                                        </Button>

                                                    </div>
                                                )}
                                            </div>
                                        )}



                                        {/* Button section on the right */}
                                        <div style={{ width: "25%" }}>
                                            <img
                                                style={{ width: "60%", height: "auto", marginBottom: "30%", marginTop: "3%" }}
                                                src={rotatePic}
                                                onClick={changeplayer2Icon}
                                                className="rotButton"
                                            />
                                            <img
                                                style={{ width: "57.5%", height: "auto", }}
                                                src={cameraPic}
                                                onClick={toggleImageVisibility2}
                                                className="rotButton"
                                            />
                                        </div>
                                    </div>

                                </td>
                                <td className={'active'}>{game.player.two.average}</td>
                                <td className={'active'}>{scores.player2}</td>
                            </tr>
                        </tbody>
                    )}

                </table>

            </div>


            {/* <Grid item xs={12}>

                            <Box sx={{ marginTop: (windowHeight / 100) * 1.5 + "px", height: (windowHeight / 10) * .5 }}>

                                <Grid container spacing={1}>
                                    <Grid item xs={4}>

                                        <div className="container">
                                            <Button
                                                style={{
                                                    height: (windowHeight / 17.5),
                                                    fontSize: ((windowHeight / 70) + 'px')
                                                }}
                                                variant="contained"
                                                color="success"
                                                onClick={swapGme}
                                            >
                                                {totalPlayersTitle}
                                            </Button>
                                        </div>
                                    </Grid>



                                    <Grid item xs={4}>
                                        <div className="container">
                                            <Button
                                                style={{
                                                    height: (windowHeight / 17.5),
                                                    fontSize: ((windowHeight / 70) + 'px')
                                                }}
                                                variant="contained"
                                                color={negativeModeColor}
                                                onClick={swapScoreMode}
                                            >
                                                {negativeModeText}
                                            </Button>
                                        </div>
                                    </Grid>


                                    <Grid item xs={4}>
                                        <div className="container">
                                            <Button
                                                style={{
                                                    height: (windowHeight / 17.5),
                                                    fontSize: ((windowHeight / 70) + 'px')
                                                }}
                                                variant="contained"
                                                color="success"
                                                onClick={nextPlayerUp}
                                            >
                                                Next Player
                                            </Button>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Box>

                        </Grid> */}






        </div>
    )

}

