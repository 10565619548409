import React from 'react';
import './CenteredDiv3.css';
import board from './board2.png';
import tab from './tab2.png';
import back from './back2.png';
import soft from './gold.png';
import strips from './strips2.png';
import darts from './darts4.png';

const CenteredDiv3 = () => {
  return (

    <div className="grid-container">
      <div className="grid-item board">
        <img src={board} alt="Board" className="grid-imageBoard" />
        <p className="caption">Full sized Dartboard</p>
      </div>
      <div className="grid-item board">
        <img src={tab} alt="Tablet" className="grid-imageTab" />
        <p className="caption">Touch Screen Tablet</p>
      </div>
      <div className="grid-item2 strips">
        <img src={back} alt="Background" className="grid-imageBack" />
        <p className="caption">Foam Wall Protector</p>
      </div>
      <div className="grid-item board">
        <img src={soft} alt="Software" className="grid-imageSoft" />
        <p className="caption">Our Latest Software </p>
      </div>
      <div className="grid-item board">
        <img src={strips} alt="Strips" className="grid-imageStrip" />
        <p className="caption">Velcro Wall Strips</p>
      </div>
      <div className="grid-item board">
        <img src={darts} alt="Darts" className="grid-imageDart" />
        <p className="caption">6 Steel Tip Darts</p>
      </div>
    </div>
  );
};

export default CenteredDiv3;
