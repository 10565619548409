import React, { useState } from 'react';
import { Button, Divider, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'; // Importing necessary Material UI components
import './Products.css';
import priceImg from './enfin2.png';
import foamImg from './back2.png';
import board from './board2.png';
import darts from './darts4.png';
import strips from './strips2.png';
import software from './gold.png';
import tablet from './tab2.png';
import a20 from './a20.png';
import WhyButton from './WhyButton'; // Adjust the path as necessary


const Pricing = () => {
    const [isChecked, setIsChecked] = useState(false); // State to track checkbox
    const [openTermsDialog, setOpenTermsDialog] = useState(false); // State to control the first dialog visibility
    const [openCheckoutDialog, setOpenCheckoutDialog] = useState(false); // State to control the second dialog visibility

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleButtonClick = () => {

        window.location.href = 'https://buy.stripe.com/00g01g4Fx4zf3g4aEG';

    };

    const handleTermsClick = () => {
        setOpenTermsDialog(true); // Open the first dialog when the terms link is clicked
        setIsChecked(false);  // Mark terms as not accepted
    };

    const handleTermsDialogClose = () => {

        setOpenTermsDialog(false); // Close the first dialog when user agrees to terms
    };

    const handleTermsDialogClose2 = () => {

        setOpenTermsDialog(false); // Close the first dialog when user agrees to terms
    };

    const handleCheckoutDialogClose = () => {
        setOpenCheckoutDialog(false); // Close the second dialog when user closes it
    };

    const handleCheckoutDialogSucesss = () => {

        
        // Trigger Facebook AddToCart event
        if (typeof fbq === 'function') {
            fbq('track', 'AddToCart');
        }


        setOpenCheckoutDialog(false); // Close the second dialog when user closes it
        setIsChecked(true);  // Mark terms as not accepted
        window.location.href = 'https://buy.stripe.com/00g01g4Fx4zf3g4aEG';
    };

    const handleDontAgree = () => {

        setIsChecked(false);  // Mark terms as not accepted
        setOpenTermsDialog(false); // Close the first dialog when user agrees to terms
    };

    /* global fbq */

    const handleAgree = () => {
        // Trigger Facebook AddToCart event
        if (typeof fbq === 'function') {
            fbq('track', 'AddToCart');
        }

        // Redirect to Stripe purchase link
        window.location.href = 'https://buy.stripe.com/00g01g4Fx4zf3g4aEG';

        // Update state
        setIsChecked(true);  // Mark terms as accepted
        setOpenTermsDialog(false);  // Close the terms dialog
    };




    return (
        <div className="productsContainer">
            <div className="pricingOptions">
                <div className="pricingOption">
                    <div className="introTitle">GET YOURS TODAY!</div>

                    <div className='introPar2' style={{ textAlign: 'left' }}>
                        <div className='salesText' style={{ marginTop: '10px', marginLeft:'0%' }}>Everything you need, ready to go!</div>
                        <img src={priceImg} alt="" style={{ maxWidth: '600px', width: '90%', height: 'auto', marginTop: '15px', display:'inline-block',alignItems:'start', justifyContent:'start' }} />
                    </div>

                    {/* Add to Cart Button and Terms & Conditions */}
                    {/* Add to Cart Button and Terms & Conditions */}
                    {/* Add to Cart Button and Terms & Conditions */}
                    <div className="buttonContainer" style={{ width: '100%', textAlign: 'center', marginBottom: '20px' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            style={{ width: '98%', fontFamily: 'MyCustomFontSemi', fontSize: '.9rem' }}
                            onClick={handleButtonClick} // Use function to handle click
                        >
                            Add to Cart
                        </Button>

                        {/* Terms and Conditions centered */}
                        <div style={{ marginTop: '10px', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                            <label htmlFor="termsCheckbox" style={{ marginLeft: '2px', fontSize: '13px', fontFamily: 'MyCustomFontBody' }}>
                                By adding this item to your cart you are agreeing to the <span onClick={handleTermsClick} style={{ color: 'blue', textDecoration: 'underline', cursor: 'pointer' }}>safety guidelines / terms and conditions</span>.
                            </label>
                        </div>
                    </div>

                    {/* First Dialog for Terms and Conditions */}
                    <Dialog open={openTermsDialog} onClose={handleTermsDialogClose}>
                        <DialogTitle>Terms and Conditions</DialogTitle>
                        <DialogContent style={{ minWidth: '300px', height: '200px', overflowY: 'auto' }}>
                            <div>
                                <div>
                                    <p>
                                        By using Scorekeeper Plus, you acknowledge that darts are sharp, potentially dangerous items. While Scorekeeper Plus is designed to enhance your dart-playing experience, we are not liable for any injuries, accidents, or damages that may occur while using the product. It is your responsibility to ensure the area in which you play is safe, free of obstacles, and suitable for dart activities. Always keep darts out of the reach of children and ensure they are used by adults or under appropriate supervision.
                                    </p>
                                    <p>
                                        Scorekeeper Plus shall not be held liable for any indirect, incidental, special, or consequential damages, including but not limited to damages for lost profits, business interruptions, or loss of data, arising out of or in connection with the use of the product, even if we have been advised of the possibility of such damages.
                                    </p>

                                    <p>
                                        Users are responsible for following proper usage guidelines and maintenance instructions to ensure the optimal performance and safety of all kit components. Improper use, handling, or modification of the product may void any implied warranty and could result in damage or injury for which Scorekeeper Plus is not responsible.
                                    </p>
                                    <p>
                                        Scorekeeper Plus does not manufacture all the components included in the kit we sell. The kit elements may vary over time due to availability from third-party suppliers. While the specific models or designs of certain items may change, rest assured that the overall quality and performance of the kit will always meet our high standards. Any substitutions made will maintain the same level of reliability and functionality you've come to expect. Scorekeeper Plus does not own the rights to the products included in the kit and simply sells them as a complete package sourced from third-party manufacturers.
                                    </p>

                                    <p>
                                        Scorekeeper Plus shall not be held responsible for any damages or injuries resulting from the misuse, unauthorized modification, or alteration of the kit components. Any such actions may compromise the integrity of the product, and Scorekeeper Plus is not liable for any resulting consequences.
                                    </p>
                                    <p>
                                        We offer a two-week refund period for the product cost, but users are responsible for return shipping costs. Please review the shipping details and associated charges before making a purchase.
                                    </p>
                                    <p>
                                        These terms and conditions are subject to change at any time, and we encourage you to review them periodically. Your continued use of Scorekeeper Plus after any changes constitutes acceptance of those changes. If you do not agree with the updated terms, you should discontinue using the product.
                                    </p>
                                </div>

                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleAgree} color="success">
                                I Agree
                            </Button>

                            <Button onClick={handleDontAgree} color="error">
                                I Don't Agree
                            </Button>

                        </DialogActions>
                    </Dialog>

                    {/* Second Dialog for Checkout Confirmation (When terms are not accepted) */}
                    <Dialog open={openCheckoutDialog} onClose={handleCheckoutDialogClose}>

                        <DialogContent>
                            <p style={{ fontFamily: 'MyCustomFontBody', fontSize: '0.9rem', marginBottom: '0px' }}>Before proceeding to checkout, please agree to the safety guidelines / terms and conditions!</p>
                        </DialogContent>
                        <DialogActions>

                            <Button onClick={handleCheckoutDialogSucesss} color="success">
                                Agree
                            </Button>
                            <Button onClick={handleCheckoutDialogClose} color="error">
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>




                    {/* Hardcoded features */}
                    <div className="feature" style={{ marginBottom: '20px' }}>
                        <div className="feature-header2" style={{ display: 'flex', }}>
                            <img src={board} alt="Feature 1 icon" className="feature-icon" style={{ marginRight: '10px', marginTop: '5x' }} />
                            <div style={{ textAlign: 'left' }}>
                                <span className="feature-title" style={{ marginLeft: '5px' }}>Full Sized Dartboard</span>
                                <p className="feature-desc" style={{ marginLeft: '5px', }}>Regulation size dart board made with self healing bristle that allows holes to close up naturally over time.</p>
                            </div>
                        </div>
                        <Divider />
                    </div>

                    <div className="feature" style={{ marginBottom: '20px' }}>
                        <div className="feature-header2" style={{ display: 'flex', }}>
                            <img src={tablet} alt="Feature 2 icon" className="feature-icon" style={{ marginRight: '10px', marginBottom: '20px' }} />
                            <div style={{ textAlign: 'left' }}>
                                <span className="feature-title" style={{ marginBottom: '10px', marginLeft: '5px' }}>Touch Screen Tablet</span>
                                <p className="feature-desc" style={{ marginLeft: '5px', }}>This is where you can access our software. Your purchase includes a 10 inch high resolution tablet with long lasting battery life.</p>
                            </div>
                        </div>
                        <Divider />
                    </div>

                    <div className="feature" style={{ marginBottom: '20px' }}>
                        <div className="feature-header2" style={{ display: 'flex', }}>
                            <img src={foamImg} alt="Feature 3 icon" className="feature-icon" style={{ marginRight: '10px', marginBottom: '20px' }} />
                            <div style={{ textAlign: 'left' }}>
                                <span className="feature-title" style={{ marginLeft: '5px' }}>Foam Wall Protector</span>
                                <p className="feature-desc" style={{ marginLeft: '5px', }}>This wall protector consists of 4 pads, which is easy to assemble and disassemble. It fits perfectly around the board and doesn't require any hanging materials.</p>
                            </div>
                        </div>
                        <Divider />
                    </div>

                    <div className="feature" style={{ marginBottom: '20px' }}>
                        <div className="feature-header2" style={{ display: 'flex', }}>
                            <img src={software} alt="Feature 4 icon" className="feature-icon" style={{ marginRight: '10px', marginBottom: '20px', marginTop: '-5px' }} />
                            <div style={{ textAlign: 'left' }}>
                                <span className="feature-title" style={{ marginLeft: '5px' }}>Our Latest Software</span>
                                <p className="feature-desc" style={{ marginLeft: '5px', }}>Your purchase grants you access to the latest version of scorekeeper plus. This also includes all future updates and new game modes.</p>
                            </div>
                        </div>
                        <Divider />
                    </div>

                    <div className="feature" style={{ marginBottom: '20px' }}>
                        <div className="feature-header2" style={{ display: 'flex' }}>
                            <img src={strips} alt="Feature 5 icon" className="feature-icon" style={{ marginRight: '10px', marginBottom: '20px', }} />
                            <div style={{ textAlign: 'left' }}>
                                <span className="feature-title" style={{ marginLeft: '5px' }}>Velcro Wall Strips</span>
                                <p className="feature-desc" style={{ marginLeft: '5px', }}>These connect to the back of the tablet making it easy to take it off the wall to charge.</p>
                            </div>
                        </div>
                        <Divider />
                    </div>

                    <div className="featur2" style={{ marginBottom: '20px' }}>
                        <div className="feature-header2" style={{ display: 'flex' }}>
                            <img src={darts} alt="Feature 6 icon" className="feature-icon" style={{ marginRight: '10px', marginBottom: '-20px' }} />
                            <div style={{ textAlign: 'left' }}>
                                <span className="feature-title" style={{ marginLeft: '5px' }}>6 Steel Tip Darts</span>
                                <p className="feature-desc" style={{ marginLeft: '5px', }}>These darts are made for the type of dartboard we provide and reduce the amount of bounce outs.</p>
                            </div>
                        </div>
                    </div>
                </div>
                <WhyButton />
            </div>
        </div>
    );
};

export default Pricing;
